import React from "react";
import styled from "styled-components";
import { scroller } from "react-scroll";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  let navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  const pricingCards = [
    {
      icon: "jigsaw",
      price: "Gratis",
      priceSuffix: "para siempre",
      title: "Starter",
      text: "Explorá las funciones principales de Tu Taller creando el espacio para tus clases.",
      offers: [
        { name: "Creación de espacio para tu taller", checked: true },
        { name: "Personalización con logo y estilo", checked: true },
        { name: "Manejo de clases recurrentes", checked: true },
        { name: "Acceso a datos y reportes", checked: false },
        { name: "Soporte personalizado", checked: false },
      ],
      studentLimit: "Límite de usuarios: 30",
      actionText: "Probalo gratis",
      action: () => navigate("/home/create"),
    },
    {
      icon: "flag",
      price: "$10,000 ARS",
      priceSuffix: "+ IVA por mes",
      title: "Classroom",
      text: "Habilitá tu espacio para todos tus alumnos con todas las funcionalidades esenciales de Tu Taller.",
      offers: [
        { name: "Creación de espacio para tu taller", checked: true },
        { name: "Personalización con logo y estilo", checked: true },
        { name: "Manejo de clases recurrentes", checked: true },
        { name: "Acceso a datos y reportes", checked: true },
        { name: "Soporte personalizado", checked: false },
      ],
      studentLimit: "Límite de usuarios: 120",
      actionText: "Suscribirme",
      action: () => navigate("/subscribe"),
    },
    {
      icon: "organization",
      price: "$15,000 ARS",
      priceSuffix: "+ IVA por mes",
      title: "Academy",
      text: "Ideal para academias y grandes talleres que necesitan más capacidad, métricas avanzadas y soporte personalizado.",
      offers: [
        { name: "Creación de espacio para tu taller", checked: true },
        { name: "Personalización con logo y estilo", checked: true },
        { name: "Manejo de clases recurrentes", checked: true },
        { name: "Acceso a datos y reportes", checked: true },
        { name: "Soporte personalizado", checked: true },
      ],
      studentLimit: "Límite de usuarios: 300",
      actionText: "Suscribirme",
      action: () => navigate("/subscribe"),
    },
    {
      icon: "performance",
      price: "A medida",
      priceSuffix: "",
      title: "Enterprise",
      text: "Para grandes instituciones que requieren la máxima capacidad, reportes avanzados y soporte prioritario.",
      offers: [
        { name: "Creación de espacio para tu taller", checked: true },
        { name: "Personalización con logo y estilo", checked: true },
        { name: "Manejo de clases recurrentes", checked: true },
        { name: "Acceso a datos y reportes", checked: true },
        { name: "Soporte personalizado", checked: true },
      ],
      studentLimit: "301 usuarios o más",
      actionText: "Contáctanos",
      action: () =>
        scroller.scrollTo("contact", { spy: true, smooth: true }),
    },
  ];

  return (
    <Fade triggerOnce={true}>
      <Wrapper id="pricing">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Nuestras suscripciones</h1>
              <p className="font13">
                Probá Tu Taller completamente gratis con un número limitado de
                alumnos.
                <br />
                Cuando sea el momento, podés desbloquear todas las funcionalidades
                suscribiéndote a Tu Taller.
              </p>
            </HeaderInfo>
          </div>

          <CarouselWrapper>
            <Slider {...sliderSettings}>
              {pricingCards.map((card, index) => (
                <TableBox key={index}>
                  <PricingTable
                    icon={card.icon}
                    price={card.price}
                    priceSuffix={card.priceSuffix}
                    title={card.title}
                    text={card.text}
                    offers={card.offers}
                    studentLimit={card.studentLimit}
                    actionText={card.actionText}
                    action={card.action}
                  />
                </TableBox>
              ))}
            </Slider>
          </CarouselWrapper>
        </div>
      </Wrapper>
    </Fade>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const CarouselWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 860px) {
    max-width: 100%;
  }
`;
const TableBox = styled.div`
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
`;
