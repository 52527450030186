import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';

// Sections
import TopNavbar from "./components/Nav/TopNavbar";
import Header from "./components/Sections/Header";
import Services from "./components/Sections/Services";
import Screenshots from "./components/Sections/Screenshots";
// import Projects from "./components/Sections/Projects";
// import Blog from "./components/Sections/Blog";
import Pricing from "./components/Sections/Pricing";
import Contact from "./components/Sections/Contact";
import Footer from "./components/Sections/Footer"

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Screenshots />      
      {/* <Projects /> */}
      {/* <Blog /> */}
      <Pricing />
      <Contact />
      <Footer />
    </>
  );
}


