import React, { useCallback, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import i18n from "@emoji-mart/data/i18n/es.json";

export const EmojiPicker = ({ emoji, setEmoji, isRight }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  // Inject global styles for em-emoji-picker
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      em-emoji-picker {
        width: 100% !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="picker" style={{width:"100%"}}>
      <div onClick={() => toggle(true)} style={{width:"fit-content"}}>
        <Button variant="contained" component="label">
          Emoji del espacio: {emoji}
        </Button>
      </div>

      {isOpen && (
        <PickerContainer 
          className={"popover " + (isRight ? "popover-bottom" : "popover-left")}
          ref={popover}
          style={{ width: "100%" }}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji)=>{setEmoji(emoji);  toggle(false);}}
            i18n={i18n}
            locale="es"
            previewPosition="none"
            dynamicWidth={true}
            style={{ width: "100%" }}
          />
        </PickerContainer>
      )}
      
    </div>
  );
};

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      if (startedInside || !startedWhenMounted) return;
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

const PickerContainer = styled.div`
  @media (max-width: 480px) {
    width: 100% !important;
  }
`;
