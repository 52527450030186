import React from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

// Components
import FullButton from "../Buttons/FullButton";
import { scroller } from "react-scroll";

// Assets
import AddImage1 from "../../assets/img/custom/undraw_building_blocks_re_5ahy.svg";
import AddImage2 from "../../assets/img/custom/undraw_app_installation_re_h36x.svg";
import AddImage3 from "../../assets/img/custom/undraw_education_f8ru.svg";
import AddImage4 from "../../assets/img/custom/undraw_start_building_re_xani.svg";

import ScreenshotBooking from "../../assets/img/custom/441shots_so.png";
import ScreenshotUsersAdmin1 from "../../assets/img/custom/445shots_so.png";
import ScreenshopBranding from "../../assets/img/custom/653shots_so.png";
import ScreenshopSubscriptions from "../../assets/img/custom/866shots_so.png";

// import ScreenshotUsersAdmin2 from "../../assets/img/custom/702shots_so.png";
// import ScreenshotImg3 from "../../assets/img/custom/622shots_so.png";
// import ScreenshotImg6 from "../../assets/img/custom/839shots_so.png";
// import ScreenshotImg8 from "../../assets/img/custom/873shots_so.png";
// import ScreenshopSubscriptionsSingle from "../../assets/img/custom/918shots_so.png";
// import ScreenshotImg10 from "../../assets/img/custom/956shots_so.png";

export default function Services() {
  let navigate = useNavigate();

  const screenshopBar = [
    {
      img: ScreenshotBooking,
      imgStyle: {marginTop: "-120px", width: "85%"},
      title: "Reservas fáciles",
      subtitle:
        "Tus alumnos podrán reservar clases online desde tu propia página web",
      description:
        "Proceso de reserva simple e integrado en tu web para facilitar la gestión y maximizar la cantidad de reservas.",
    },
    {
      img: ScreenshopSubscriptions,
      imgStyle: {marginTop: "-140px", width: "85%"},
      title: "Manejo de suscripciones",
      subtitle:
        "Tus alumnos podrán reservar clases online desde tu propia página web",
      description:
        "Proceso de reserva simple e integrado en tu web para facilitar la gestión y maximizar la cantidad de reservas.",
    },
    {
      img: ScreenshotUsersAdmin1,
      imgStyle: {marginTop: "-60px", width: "100%"},
      title: "Administración de alumnos",
      subtitle:
        "Toda la información de tus estudiantes organizada y a tu alcance.",
      description:
        "Gestioná fácilmente datos, historial de pagos, reservas y créditos de cada alumno desde un panel unificado.",
    },
    {
      img: ScreenshopBranding,
      imgStyle: {marginTop: "-60px", width: "100%"},
      title: "Un espacio para tu marca",
      subtitle:
        "Plataforma con tu identidad visual: dirección, colores y logo propios.",
      description:
        "Reflejá tu marca en cada detalle y ofrecé una experiencia única a tus alumnos.",
    },
  ];

  return (
    <Wrapper id="screenshots">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold"> Conocé la App </h1>
          </HeaderInfo>
        </div>

        {screenshopBar.map((item, i) => {
          const isEven = i % 2 === 0;

          const textBlock = (
            <ScreenshotTextContainer>
              <h4 className="font15 semiBold">{item.subtitle}</h4>
              <h2 className="font40 extraBold">{item.title}</h2>
              <hr />
              <p className="font12">{item.description}</p>
            </ScreenshotTextContainer>
          );

          const imageBlock = (
            <AddInner>
              <ScreenshotImg src={item.img} alt="office" style={item.imgStyle}/>
            </AddInner>
          );

          const imageBlockMobile = (
            <AddInner>
              <ScreenshotImgMobile src={item.img} alt="office"/>
            </AddInner>
          )

          return (
            <ScreenshotContainer key={i} className={isEven ? "wcBlueBg" : "wcLightBg"}>
              <div className="container">
                <Advertising>
                  {isEven ? (
                    <>
                      <AddMobile>
                        <Fade direction="up">{imageBlockMobile}</Fade>
                      </AddMobile>
                      <AddLeft>
                        <Fade direction="left">{textBlock}</Fade>
                      </AddLeft>
                      <AddRight>
                        <Fade direction="right">{imageBlock}</Fade>
                      </AddRight>
                    </>
                  ) : (
                    <>
                      <AddMobile>
                        <Fade direction="up">{imageBlockMobile}</Fade>
                      </AddMobile>                      <AddLeft>
                        <Fade direction="left">{imageBlock}</Fade>
                      </AddLeft>
                      <AddRight>
                        <Fade direction="right">{textBlock}</Fade>
                      </AddRight>
                    </>
                  )}
                </Advertising>
              </div>
            </ScreenshotContainer>
          );
        })}
        <div className="lightBg">
          <div className="container">
            <Fade direction="right">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h4 className="font15 semiBold">
                    Unas palabras sobre Tu Taller
                  </h4>
                  <h2 className="font40 extraBold">La app para tu taller</h2>
                  <hr />
                  <p className="font12">
                    Nuestra misión es crear una app para que emprendedores,
                    docentes y profesionales inviertan menos tiempo en
                    gestionando sus talleres y cursos.
                  </p>
                  <ButtonsRow
                    className="flexNullCenter"
                    style={{ margin: "30px 0" }}
                  >
                    <div style={{ width: "190px" }}>
                      <FullButton
                        title="Probá la App ahora"
                        action={() => navigate("/home/create")}
                      />
                    </div>
                    <div style={{ width: "190px", marginLeft: "15px" }}>
                      <FullButton
                        title="Contactanos"
                        border
                        action={() =>
                          scroller.scrollTo("contact", {
                            spy: true,
                            smooth: true,
                          })
                        }
                      />
                    </div>
                  </ButtonsRow>
                </AddLeft>
                <AddRight>
                  <AddInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src={AddImage1} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src={AddImage2} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3>
                        <img src={AddImage3} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src={AddImage4} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddInner>
                </AddRight>
              </Advertising>
            </Fade>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  position: relative;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  order: 1;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    padding-top: 1.5em;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  top: -70px;
  right: 0;
  order: 2;
  @media (max-width: 860px) {
    width: 100%;
    // position: relative;
    top: -40px;
  }
`;
const AddMobile = styled.div`
  display: none;
  @media (max-width: 860px) {
    width: 100%;
    display: block;
    order: 0;
  }
`;
const AddInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
  }
`;

const ScreenshotImg = styled.img`
  width: 100%;
  @media (max-width: 860px) {
    display: none;
  }
`;

const ScreenshotImgMobile = styled.img`
  display: none;
  width: 100%;
  @media (max-width: 860px) {
    display: block;
  }
`;

const ScreenshotContainer = styled.div`
  height: 19em;
  margin: 3em 0em;

  @media (max-width: 860px) {
    height: auto;
    margin: 1em 0em;
    padding: 1em;
  }
`;

const ScreenshotTextContainer = styled.div`
  padding-top: 5em;
  @media (max-width: 1160px) {
    padding-top: 0em;
  }
`