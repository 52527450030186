import React from "react";
import styled from "styled-components";

// Components
import FullButton from "../Buttons/FullButton";

// Assets
import JigsawIcon from "../../assets/img/custom/marketing-office-puzzle-svgrepo-com.svg";
import FlagIcon from "../../assets/img/custom/marketing-office-stair-svgrepo-com.svg";
import PerformanceIcon from "../../assets/img/custom/marketing-office-performance-svgrepo-com.svg";
import OrganizationIcon from "../../assets/img/custom/marketing-office-organization-svgrepo-com.svg";

import CheckMark from "../../assets/svg/Checkmark";
import Cross from "../../assets/svg/Cross";

export default function PricingTable({
  icon,
  price,
  priceSuffix,
  title,
  text,
  offers,
  studentLimit,
  action,
  actionText,
}) {
  let getIcon;

  switch (icon) {
    case "jigsaw":
      getIcon = <Icon src={JigsawIcon} alt="Starter" />;
      break;
    case "flag":
      getIcon = <Icon src={FlagIcon} alt="Suscribed" />;
      break;
    case "performance":
      getIcon = <Icon src={PerformanceIcon} alt="Suscribed" />;
      break;
    case "organization":
      getIcon = <Icon src={OrganizationIcon} alt="Suscribed" />;
      break;
    default:
      getIcon = <Icon src={FlagIcon} alt="Suscribed" />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <div>
          <p className="font30 extraBold">{price}</p>
          <PriceSuffixContainer className="font10">
            {priceSuffix}
          </PriceSuffixContainer>
        </div>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <br />
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div
                className="flexNullCenter"
                style={{ margin: "15px 0" }}
                key={index}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "15px",
                  }}
                >
                  {item.checked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}>
                      <Cross style={{ transform: "scale(0.7)" }} />
                    </div>
                  )}
                </div>
                <p className="font20">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <hr />
      <div className="font20 extraBold">{studentLimit}</div>

      <div style={{ maxWidth: "220px", margin: "30px auto 0 auto" }}>
        <FullButton title={actionText} action={action} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const Icon = styled.img`
  width: 5em;
`;

const PriceSuffixContainer = styled.p`
  text-align: right;
  color: dark-gray;
`;
