import React from "react";
import styled from "styled-components";

import { Fade } from "react-awesome-reveal";

// Components
import ServiceBox from "../Elements/ServiceBoxWC";

// Assets

export default function Services() {
  const cards = [
    {
      icon: "fa-user-gear",
      colorStyle: "var(--wc-main-secondary)",
      title: "Manejo de Alumnos",
      description:
        "Gestiona fácilmente tu base de alumnos. Da de alta a nuevos estudiantes y editá perfiles existentes para un seguimiento",
    },
    {
      icon: "fa-user-check",
      colorStyle: "var(--wc-main-primary)",
      title: "Autoservicio para Alumnos",
      description:
        "Tus alumnos tendrán la libertad de reservar, cancelar y recuperar clases de forma sencilla, sin complicaciones.",
    },
    {
      icon: "fa-calendar",
      colorStyle: "var(--wc-main-secondary)",
      title: "Clases Recurrentes o Simples",
      description:
        "Adaptá tu calendario a tus necesidades. Programá clases únicas o periódicas.",
    },
    {
      icon: "fa-palette",
      colorStyle: "var(--wc-main-primary)",
      title: "Espacio Personalizado",
      description:
        "Hacé que tu plataforma refleje tu identidad. Personalizá tu espacio con logos y la paleta de colores de tu marca.",
    },
    {
      icon: "fa-envelope-open",
      colorStyle: "var(--wc-main-secondary)",
      title: "Notificaciones por Email",
      description:
        "Tus alumnos recibirán confirmaciones de reservas y notificaciones de cancelaciones automáticamente.",
    },
    {
      icon: "fa-chart-line",
      colorStyle: "var(--wc-main-primary)",
      title: "Métricas Detalladas",
      description:
        "Accede a métricas clave para optimizar la gestión y mejorar la toma de decisiones.",
    },
  ];

  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Funcionalidades</h1>
          </HeaderInfo>
          <ServiceBoxRow
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Fade cascade damping={0.15} className="flex">
              {cards.map((card, i) => {
                return (
                  <ServiceBoxWrapper key={card.title}>
                    <ServiceBox
                      icon={card.icon}
                      title={card.title}
                      subtitle={card.description}
                      colorStyle={card.colorStyle}
                    />
                  </ServiceBoxWrapper>
                );
              })}
            </Fade>
          </ServiceBoxRow>
        </div>
        {/* <div className="lightBg">
          <div className="container">
            <Fade direction="right">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h4 className="font15 semiBold">
                    Unas palabras sobre Tu Taller
                  </h4>
                  <h2 className="font40 extraBold">La app para tu taller</h2>
                  <hr />
                  <p className="font12">
                    Nuestra misión es brindar una app para que emprendedores,
                    docentes y profesionales inviertan menos tiempo en
                    gestionando sus talleres y cursos.
                  </p>
                  <ButtonsRow
                    className="flexNullCenter"
                    style={{ margin: "30px 0" }}
                  >
                    <div style={{ width: "190px" }}>
                      <FullButton
                        title="Probá la App ahora"
                        action={() => navigate("/home/create")}
                      />
                    </div>
                    <div style={{ width: "190px", marginLeft: "15px" }}>
                      <FullButton
                        title="Contactanos"
                        border
                        action={() =>
                          scroller.scrollTo("contact", {
                            spy: true,
                            smooth: true,
                          })
                        }
                      />
                    </div>
                  </ButtonsRow>
                </AddLeft>
                <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src={AddImage1} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src={AddImage2} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3>
                        <img src={AddImage3} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src={AddImage4} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </Fade>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 3em 0em 3em 0em;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  flex-grow: 1;
  width: 33%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;