import React from "react";
import PropTypes from "prop-types";
import { Container, Typography, Link as MuiLink } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom";

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer style={{ padding: '16px', backgroundColor: '#fff', borderTop: '1px solid #e0e0e0' }}>
    <Container maxWidth={contained ? 'lg' : false}>
      <Grid container spacing={2} alignItems="center">
        <Grid>
          {menuItems.map((item, idx) => (
            <MuiLink
              component={Link}
              to={item.to}
              key={idx}
              variant="body2"
              style={{ marginRight: '16px', textDecoration: 'none', color: '#000' }}
            >
              {item.title}
            </MuiLink>
          ))}
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2" align="right">
            {copyright}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © 2018 DesignRevision",
  menuItems: [
    {
      title: "Home",
      to: "#"
    },
    {
      title: "Services",
      to: "#"
    },
    {
      title: "About",
      to: "#"
    },
    {
      title: "Products",
      to: "#"
    },
    {
      title: "Blog",
      to: "#"
    }
  ]
};

export default MainFooter;
